<template>
    <!-- Comum para todas as páginas do de-para -->
    <LayoutTelas id="tela__depara" :titulo="this.$route.meta.titulo">
      <div v-if="this.$route.name === 'rota-depara'">
        <h5>Selecione uma opção:</h5>
        <ul>
          <li v-for="item in dados.menu">
            <router-link :to="`${item.path}${dados.auth}`">{{item.nome}}</router-link>
          </li>
        </ul>
      </div>

      <!-- necessário para as nested routes /depara/... -->
      <router-view></router-view>
    </LayoutTelas>
</template>

<script>
export default {
  created() {
    this.dados.auth = this.$route.params.auth;
  },
  data() {
    return {
      dados: {
        auth:'',
        menu: [
          {nome:"Mensagem", path:"/depara/mensagem/"},
          {nome:"Produto", path:"/depara/produto/"},
          {nome:"Ncm", path:"/depara/ncm/"},
          {nome:"Item Sped", path:"/depara/itemsped/"},
          {nome:"Conta Contábil", path:"/depara/contacontabil/"}
        ]
      }
    }
  }
}
</script>

<style lang="scss" scope>
  @import "@/common/scss/breakpoints.scss";

  #tela__depara {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    @media (min-width: $medium-size) {
      min-width: $small-size;
    }
  }

  .filtro {
    display: grid;
    grid-template-columns: 1fr;
    
    @media (min-width: $medium-size) {
      width: 100%;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 0.8em;
    }
  }

  .resultado {
    font-size: 12px;    
    @media (min-width: $medium-size) {
      width: 30%;
    }
  }

  .botoes {
    display: grid;
    width: 100%;
    align-items: center; /* left and right */
    justify-content: end; /* up and down */
  }

  .botoes button {
    margin-left: 10px;
    width: 120px
  }

  .btn {
    &Processar {
      @media (min-width: $medium-size) {
        grid-column: 1 / 2;
        margin-top: 0.2rem;
        margin-bottom: 0.9rem;
      } 
    }
    &Adicionar {
      @media (min-width: $medium-size) {
        grid-column: 2 / 3;
        margin-top: 0.2rem;
        margin-bottom: 0.9rem;
      } 
    }
  }

  .concluido {
    @media (min-width: $medium-size) {
      grid-column: 3 / -1;
      align-self: center;
      padding-left: 0.5rem;
    }
  }

  .loader {
    @media (min-width: $medium-size) {
      grid-column: 1 / -1;
      align-self: center;
    }
    &Spinner {
      align-self: center;
    }
    &Check {
      color: #00cf2d;
    }
  }

  .loteCard {
    @media (min-width: $medium-size) {
      grid-column: 1 / -1;
    }
  }

  .tableDeParaMensagem {
    @media (min-width: $medium-size) {
      grid-column: 1 / -1;
    }
  }

  .btnAction {
    padding: 0px 5px 0px 5px;
    margin: 1
  }

  .erroModal {
    color: #FF0000;
  }

  #footerTable {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }

  .paginacao {
    display: flex;
    justify-content: flex-end;

    &__seletor {
      margin: 0;
    }
  }
</style>